import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import GlobalAlerts from "../components/global-alerts";
import { useEffect, useState } from "react";
import {
  Box2Fill,
  BoxSeam,
  CameraFill,
  FileImage,
  MenuButton,
  Truck,
  UpcScan,
  XDiamond,
} from "react-bootstrap-icons";
import GoogleAppsScriptIframe from "../components/google-apps-script-iframe";
import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";
import ItemLocationScanPage from "./item-location";
import ImageUploadTool from "./image-upload-tool";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

type MenuOptions =
  | "menu"
  | "outbound"
  | "stockimages"
  | "auctionpallet"
  | "palletmanager"
  | "retailStockLocation"
  | "itemlocation"
  | "imagetool";

const ScannerHomePage = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = (param: string) => {
    return new URLSearchParams(location.search).get(param);
  };

  const initialMenuOption = (getQueryParam("option") as MenuOptions) || "menu";
  const fromMenu = getQueryParam("fromMenu") === "true";
  const [selectedMenuOption, setMenuOption] =
    useState<MenuOptions>(initialMenuOption);
  const [loading, setLoading] = useState<boolean>(true);
  const [featureUnavailable, setFeatureUnavailable] = useState<boolean>(false);

  // Update the URL when the menu option changes
  useEffect(() => {
    if (!loading && selectedMenuOption) {
      // Ensure selectedMenuOption is truthy
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.get("option") !== selectedMenuOption) {
        queryParams.set("option", selectedMenuOption);
        navigate(`/scannerhome?${queryParams.toString()}`, { replace: true });
      }
    }
  }, [selectedMenuOption, loading, navigate, location.search]);

  const scannerOutboundConsignmentEnabled = useFeatureFlagEnabled(
    "scanner_outboundConsignment"
  );
  const stockImagesEnabled = useFeatureFlagEnabled("scanner_stockImages");
  const auctionPalletDespatchEnabled = useFeatureFlagEnabled(
    "scanner_auctionPalletDespatch"
  );
  const palletManagerEnabled = useFeatureFlagEnabled("scanner_palletManager");
  const retailStockLocationEnabled = useFeatureFlagEnabled(
    "scanner_retailStockLocation"
  );
  const itemLocationScannerEnabled = useFeatureFlagEnabled(
    "scanner_itemLocationScanner"
  );
  const imageToolEnabled = useFeatureFlagEnabled("scanner_imageTool");

  const scannerOutboundConsignmentURL = useFeatureFlagPayload(
    "scanner_outboundConsignment"
  ) as string;
  const stockImagesURL = useFeatureFlagPayload("scanner_stockImages") as string;
  const auctionPalletDespatchURL = useFeatureFlagPayload(
    "scanner_auctionPalletDespatch"
  ) as string;
  const palletManagerURL = useFeatureFlagPayload(
    "scanner_palletManager"
  ) as string;
  const retailStockLocationURL = useFeatureFlagPayload(
    "scanner_retailStockLocation"
  ) as string;

  // Set loading to false once feature flags are loaded
  useEffect(() => {
    if (
      scannerOutboundConsignmentEnabled !== undefined &&
      stockImagesEnabled !== undefined &&
      auctionPalletDespatchEnabled !== undefined &&
      palletManagerEnabled !== undefined &&
      retailStockLocationEnabled !== undefined &&
      itemLocationScannerEnabled !== undefined &&
      imageToolEnabled !== undefined
    ) {
      setLoading(false);
    }
  }, [
    scannerOutboundConsignmentEnabled,
    stockImagesEnabled,
    auctionPalletDespatchEnabled,
    palletManagerEnabled,
    retailStockLocationEnabled,
    itemLocationScannerEnabled,
    imageToolEnabled,
  ]);

  // Validate the selected menu option against feature flags
  useEffect(() => {
    if (!loading) {
      const isOptionValid = (option: MenuOptions): boolean => {
        switch (option) {
          case "outbound":
            return scannerOutboundConsignmentEnabled ?? false;
          case "stockimages":
            return stockImagesEnabled ?? false;
          case "auctionpallet":
            return auctionPalletDespatchEnabled ?? false;
          case "palletmanager":
            return palletManagerEnabled ?? false;
          case "retailStockLocation":
            return retailStockLocationEnabled ?? false;
          case "itemlocation":
            return itemLocationScannerEnabled ?? false;
          case "imagetool":
            return imageToolEnabled ?? false;
          case "menu":
            return true;
          default:
            return false;
        }
      };

      if (!isOptionValid(selectedMenuOption)) {
        setFeatureUnavailable(true);
      } else {
        setFeatureUnavailable(false);
      }
    }
  }, [
    selectedMenuOption,
    scannerOutboundConsignmentEnabled,
    stockImagesEnabled,
    auctionPalletDespatchEnabled,
    palletManagerEnabled,
    retailStockLocationEnabled,
    itemLocationScannerEnabled,
    imageToolEnabled,
    loading,
  ]);

  const ButtonSubtitle = ({ subtitle }: { subtitle: string }) => (
    <p style={{ margin: "0", fontSize: "0.6em" }}>{subtitle}</p>
  );

  const menuUI = (
    <Stack gap={3}>
      {scannerOutboundConsignmentEnabled ? (
        <Button
          variant="success"
          size="lg"
          onClick={() => setMenuOption("outbound")}
        >
          Outbound Consignment <BoxSeam className="ms-2" />
        </Button>
      ) : null}
      {stockImagesEnabled ? (
        <Button
          variant="warning"
          size="lg"
          onClick={() => setMenuOption("stockimages")}
        >
          Stock Images <CameraFill className="ms-2" />
        </Button>
      ) : null}
      {auctionPalletDespatchEnabled ? (
        <Button
          variant="primary"
          size="lg"
          onClick={() => setMenuOption("auctionpallet")}
        >
          Auction Pallet Despatch <Truck className="ms-2" />
        </Button>
      ) : null}
      {palletManagerEnabled ? (
        <Button
          variant="info"
          size="lg"
          onClick={() => setMenuOption("palletmanager")}
        >
          Pallet Manager <XDiamond className="ms-2" />
        </Button>
      ) : null}
      {retailStockLocationEnabled ? (
        <Button
          variant="info"
          size="lg"
          onClick={() => setMenuOption("retailStockLocation")}
        >
          Retail Stock Location <Box2Fill className="ms-2" />
        </Button>
      ) : null}
      {itemLocationScannerEnabled ? (
        <Button
          variant="success"
          size="lg"
          style={{ backgroundColor: "rgb(11, 104, 140)" }}
          onClick={() => setMenuOption("itemlocation")}
        >
          <Badge className="me-3" bg="danger">
            New
          </Badge>
          Item Scanner <UpcScan className="ms-2" />
          <ButtonSubtitle subtitle="Scan and store locations of items" />
        </Button>
      ) : null}
      {imageToolEnabled ? (
        <Button
          variant="success"
          size="lg"
          style={{ backgroundColor: "rgb(127, 0, 123)" }}
          onClick={() => setMenuOption("imagetool")}
        >
          <Badge className="me-3" bg="danger">
            New
          </Badge>
          Image Upload <FileImage className="ms-2" />
          <ButtonSubtitle subtitle="Take and review images for items" />
        </Button>
      ) : null}
    </Stack>
  );

  const outboundConsignmentUI = (
    <>
      <GoogleAppsScriptIframe
        src={scannerOutboundConsignmentURL}
        title="Outbound Consignment Scanner"
      />
    </>
  );
  const stockImagesUI = (
    <>
      <GoogleAppsScriptIframe src={stockImagesURL} title="Stock Images" />
    </>
  );
  const auctionPalletDespatchUI = (
    <>
      <GoogleAppsScriptIframe
        src={auctionPalletDespatchURL}
        title="Auction Pallet Despatch"
      />
    </>
  );
  const palletManagerUI = (
    <>
      <GoogleAppsScriptIframe src={palletManagerURL} title="Pallet Manager" />
    </>
  );
  const retailStockLocationUI = (
    <>
      <GoogleAppsScriptIframe
        src={retailStockLocationURL}
        title="Retail Stock Location"
      />
    </>
  );

  const itemLocationUi = (
    <>
      <ItemLocationScanPage />
    </>
  );

  const imageToolUi = (
    <>
      <ImageUploadTool />
    </>
  );

  const MenuOptionUI = () => {
    switch (selectedMenuOption) {
      case "outbound":
        return outboundConsignmentUI;
      case "stockimages":
        return stockImagesUI;
      case "auctionpallet":
        return auctionPalletDespatchUI;
      case "palletmanager":
        return palletManagerUI;
      case "retailStockLocation":
        return retailStockLocationUI;
      case "itemlocation":
        return itemLocationUi;
      case "imagetool":
        return imageToolUi;
      case "menu":
      default:
        return menuUI;
    }
  };

  return (
    <Container fluid className="mt-3">
      {selectedMenuOption === "menu" ? <GlobalAlerts /> : null}
      {selectedMenuOption !== "menu" && fromMenu && (
        <Button
          variant="outline-primary"
          style={{ width: "100%" }}
          size="lg"
          onClick={() => {
            setMenuOption("menu");
          }}
        >
          <MenuButton className="me-2" />
          Return to Menu
        </Button>
      )}
      {featureUnavailable ? (
        <Alert
          variant="danger"
          onClose={() => setFeatureUnavailable(false)}
          dismissible
        >
          This feature is not currently available. Please contact support for
          further information.
        </Alert>
      ) : (
        <div className="mt-2">
          <MenuOptionUI />
        </div>
      )}
    </Container>
  );
};

export default ScannerHomePage;
