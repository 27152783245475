import React, { CSSProperties, useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import { Camera, Floppy2, Image, Link45deg } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";
import { useDebugMode } from "../../hooks/useDebugMode";
import { detectIsMobile } from "../../helpers/mobile";

export type PreviewSizes = "1" | "2" | "3" | "4" | "5" | "6";

// Typescript support for Modernizr
declare global {
  interface Window {
    Modernizr: {
      capture: boolean;
    };
  }
}

export interface ImageUploadComponentProps {
  onDrop?: (acceptedFiles: FileWithComment[]) => void;
  onCommentChange?: (fileId: string, comment: string) => void;
  onRemoveFile?: (file: FileWithComment, fileIndex: number) => void;
  maxFiles?: number;
  showMaxFilesText?: boolean;

  previewSizes?: {
    xs: PreviewSizes;
    sm: PreviewSizes;
    md: PreviewSizes;
    lg: PreviewSizes;
    xl: PreviewSizes;
    xxl: PreviewSizes;
  };

  /**
   * Optional settings for saving the images
   */
  saveSettings?: {
    /**
     * Function called when the save button is clicked
     * @param files files to save
     */
    onSave: (files: FileWithComment[]) => void;

    /**
     * Optional text for the save button
     * @default "Save Images"
     */
    saveButtonText?: string;
  };

  isUploading?: boolean;

  /**
   * A function that will be called when the user shouldn't be able to leave the page
   * @param userEntryInProgress set according to the user entry status
   */
  requiresUserEntry?: (userEntryInProgress: boolean) => void;
}

export interface FileWithComment {
  file: File;
  comment?: string;
}

const imgStyle: CSSProperties = {
  objectFit: "scale-down",
  aspectRatio: "1/1",
  maxHeight: "500px",
  maxWidth: "750px",
};

const ImageUploadComponent = ({
  onDrop,
  onRemoveFile: deleteFile,
  maxFiles = 1,
  showMaxFilesText = true,
  saveSettings,
  previewSizes,
  isUploading,
  requiresUserEntry,
}: ImageUploadComponentProps): JSX.Element => {
  const [files, setFiles] = useState<FileWithComment[]>([]);
  const [capture, setCapture] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [urlError, setUrlError] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const debugMode = useDebugMode();
  const isMobile = detectIsMobile();

  const handleCommentChange = (
    fileId: string,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newComment = event.target.value;
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((file) => {
        if (file.file.name === fileId) {
          return { ...file, comment: newComment };
        }
        return file;
      });

      // Call onDrop with updated files
      if (onDrop) {
        onDrop(updatedFiles);
      }

      return updatedFiles;
    });

    if (debugMode) {
      console.debug("Setting comment for file", fileId, newComment);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles,
    accept: {
      "image/*": [],
    },
    multiple: maxFiles > 1,
    onDrop: (acceptedFiles) => {
      const newFile = acceptedFiles.map((file): FileWithComment => {
        const fi = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        return {
          file: fi,
          comment: "",
        };
      });
      const newFiles = [...files, ...newFile];
      setFiles(newFiles);
      if (debugMode) {
        console.debug("New files", newFiles);
      }
      if (onDrop) {
        onDrop(newFiles);
      }
    },
  });

  const removeFile = (file: FileWithComment) => () => {
    const newFiles = [...files];
    const indexOfFile = newFiles.indexOf(file);
    if (debugMode) {
      console.info("Removing file from uploader", file, indexOfFile);
    }
    newFiles.splice(indexOfFile, 1);
    setFiles(newFiles);

    // call the delete function from incoming props
    if (deleteFile) {
      deleteFile(file, indexOfFile);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleTakeImageClick = () => {
    setTimeout(() => {
      fileInputRef.current?.click();
    }, 100);
  };

  const fetchImageFromUrl = async (
    url: string
  ): Promise<FileWithComment | null> => {
    if (!url || url === "" || !url.startsWith("http")) {
      setUrlError(
        "Invalid URL. Please enter a valid URL starting with http or https."
      );
      return null;
    }

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], "fetched-image.jpg", { type: blob.type });
      const fileWithComment: FileWithComment = {
        file: Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
        comment: "",
      };
      setImageUrl("");
      setUrlError("");
      return fileWithComment;
    } catch (error) {
      console.error("Error fetching image from URL:", error);
      setUrlError(
        "Error fetching image from URL. Please try again. If this persists, you may need to upload the image from your device."
      );
      return null;
    }
  };

  useEffect(() => {
    if (requiresUserEntry) {
      requiresUserEntry(showUrlInput || isFetching);
    }
  }, [isFetching, requiresUserEntry, showUrlInput]);

  const handleUrlSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFetching(true);
    const fetchedFile = await fetchImageFromUrl(imageUrl);
    if (fetchedFile) {
      const newFiles = [...files, fetchedFile];
      setFiles(newFiles);
      if (debugMode) {
        console.debug("Fetched file from URL:", fetchedFile);
      }
      if (onDrop) {
        onDrop(newFiles);
      }
      setShowUrlInput(false);
    }
    setIsFetching(false);
  };

  const thumbs = files.map((file, fileIndex) => (
    <>
      <Card
        key={`imgcard-${fileIndex}`}
        style={{ marginTop: "5px", marginBottom: "5px" }}
      >
        <Card.Img
          src={(file.file as any).preview}
          style={imgStyle}
          alt={file.file.name}
        />
        <Card.ImgOverlay>
          <Card.Text>
            <Button
              style={{ width: "100%", maxWidth: "5em" }}
              variant="danger"
              onClick={removeFile(file)}
              size="sm"
            >
              Remove
            </Button>
          </Card.Text>
        </Card.ImgOverlay>
      </Card>
      <Row>
        <Col>
          <Form.Group className="mb-1">
            <Form.Control
              value={file.comment ?? ""}
              onChange={(event) =>
                handleCommentChange(
                  file.file.name,
                  event as React.ChangeEvent<HTMLTextAreaElement>
                )
              }
              placeholder="Add a comment"
              style={{ width: "100%" }}
              as="textarea"
              rows={3}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  ));

  useEffect(
    // Nested function is intentional - returning a function in a React Component
    // is a cleanup function that will run when the component is unmounted
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL((file as any).preview));
    },
    [files]
  );

  return (
    <Row>
      <Stack className="container">
        {showUrlInput ? (
          <Row>
            <Col>
              <Form noValidate onSubmit={handleUrlSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter image URL"
                    value={imageUrl}
                    isInvalid={urlError !== ""}
                    onChange={(e) => setImageUrl(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {urlError}
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ textAlign: "right", marginTop: "1em" }}>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShowUrlInput(false);
                      setUrlError("");
                      setImageUrl("");
                    }}
                    disabled={isFetching} // Disable button when fetching
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={
                      isUploading || files.length >= maxFiles || isFetching
                    }
                    style={{ marginLeft: "0.5em" }}
                  >
                    {isFetching ? "Fetching..." : "Fetch Image"}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        ) : (
          <>
            <Row
              direction="horizontal"
              xs={previewSizes?.xs ? previewSizes.xs : "1"}
              sm={previewSizes?.sm ? previewSizes.sm : "2"}
              md={previewSizes?.md ? previewSizes.md : "3"}
              lg={previewSizes?.lg ? previewSizes.lg : "4"}
              xl={previewSizes?.xl ? previewSizes.xl : "5"}
              xxl={previewSizes?.xxl ? previewSizes.xxl : "6"}
            >
              {thumbs.map((thumb, i) => (
                <Col key={i}>{thumb}</Col>
              ))}
            </Row>
            <div
              {...getRootProps({ className: "dropzone" })}
              onClick={(e) => e.stopPropagation}
              style={{ textAlign: "center" }}
            >
              <input
                {...getInputProps()}
                capture={capture ? "environment" : undefined}
              />
              <Button
                type="button"
                disabled={isUploading || files.length >= maxFiles}
                onClick={() => {
                  setCapture(false);
                  setTimeout(() => {
                    open();
                  }, 100);
                }}
              >
                Add Image{maxFiles > 1 ? "(s)" : ""} from file
                <Image style={{ marginLeft: "0.5em" }} size={24}></Image>
              </Button>
              {!isMobile ? (
                <Button
                  type="button"
                  variant="warning"
                  onClick={() => setShowUrlInput(true)}
                  disabled={isUploading || files.length >= maxFiles}
                  style={{ marginLeft: "1em" }}
                >
                  Fetch Image from URL
                  <Link45deg style={{ marginLeft: "0.5em" }} size={24} />
                </Button>
              ) : null}
              {window.Modernizr.capture && (
                <Button
                  type="button"
                  disabled={isUploading || files.length >= maxFiles}
                  style={{ marginLeft: "1em" }}
                  onClick={handleTakeImageClick}
                  variant="success"
                >
                  Take Image{maxFiles > 1 ? "(s)" : ""}
                  <Camera style={{ marginLeft: "0.5em" }} size={24} />
                </Button>
              )}
              {/* Hidden File Input */}
              <input
                type="file"
                accept="image/*"
                capture="environment"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const capturedFile = e.target.files[0];
                    const newFile = {
                      file: Object.assign(capturedFile, {
                        preview: URL.createObjectURL(capturedFile),
                      }),
                      comment: "",
                    };
                    const newFiles = [...files, newFile];
                    setFiles(newFiles);
                    if (debugMode) {
                      console.debug("Captured file:", capturedFile);
                    }
                    if (onDrop) {
                      onDrop(newFiles);
                    }
                  }
                }}
              />
              {!!saveSettings ? (
                <Button
                  onClick={() => {
                    saveSettings.onSave(files);
                  }}
                  style={{ marginLeft: "1em" }}
                  disabled={isUploading}
                  variant="success"
                >
                  {saveSettings.saveButtonText
                    ? saveSettings.saveButtonText
                    : "Save Images"}
                  <Floppy2 style={{ marginLeft: "0.5em" }} size={20} />
                </Button>
              ) : null}
            </div>
            {showMaxFilesText ? (
              <div style={{ textAlign: "center" }}>
                {maxFiles > 1 ? (
                  <b>Maximum number of images: {maxFiles}</b>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </Stack>
      {debugMode ? (
        <i style={{ color: "coral" }}>
          Capture supported by browser:{" "}
          <code>{JSON.stringify(!!window.Modernizr.capture)}</code>
        </i>
      ) : null}
    </Row>
  );
};

export default ImageUploadComponent;
