import { useState } from "react";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import { InventoryItemStoredImage } from "../../external/inventory-component/types";
import ImageCarousel from "../image-carousel";
import { useDebugMode } from "../../hooks/useDebugMode";
import ImageRow from "./image-row";

export interface InventoryImagesProps {
  images: InventoryItemStoredImage[];
  deleteImageFunction?: (image: InventoryItemStoredImage) => Promise<any>;
  maxWidth?: string;
}

const InventoryImagesComponent = ({
  images,
  deleteImageFunction,
  maxWidth,
}: InventoryImagesProps) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState<InventoryItemStoredImage>(
    images[0]
  );
  const [blockActions, setBlockActions] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const debugMode = useDebugMode();

  return (
    <>
      <ImageCarousel
        imageUrls={images.map((image) => image.imageUrl)}
        autoSlide={false}
        maxWidth={maxWidth ?? "100%"}
        rounded={true}
        noAnimation={true}
        imageCustomStyles={{
          objectFit: "scale-down",
          aspectRatio: "1/1",
        }}
        controlledProps={{
          selectedIndex: selectedImageIndex,
          onSelect: (i) => {
            setSelectedImageIndex(i);
            setSelectedImage(images[i]);
          },
          currentSlideOverlay: (
            <>
              <b>
                Image {selectedImageIndex + 1} of {images.length}
              </b>
              <br />
              {selectedImage?.comment ? selectedImage?.comment : null}

              {deleteImageFunction ? (
                <Button
                  variant="danger"
                  disabled={blockActions}
                  className={
                    confirmDelete && !blockActions ? "confirm-delete" : ""
                  }
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation(); // prevents parent onClick from firing
                    if (confirmDelete) {
                      setBlockActions(true);
                      await deleteImageFunction(selectedImage);
                      setBlockActions(false);
                      setConfirmDelete(false);
                    } else {
                      setConfirmDelete(true);
                    }
                  }}
                >
                  {blockActions ? (
                    <>
                      Deleting... <Spinner size="sm" />
                    </>
                  ) : confirmDelete ? (
                    "Confirm Delete?"
                  ) : (
                    "Delete image"
                  )}
                </Button>
              ) : null}
            </>
          ),
        }}
      />
      <ImageRow
        imageUrls={images.map((image) => image.imageUrl)}
        selectedImageIndex={selectedImageIndex}
        selectImage={(i) => {
          setSelectedImageIndex(i);
          setSelectedImage(images[i]);
        }}
      />
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Image Details</Accordion.Header>
          <Accordion.Body>
            <>
              {selectedImage?.comment ? (
                <>
                  <b>Comment</b>
                  <p>{selectedImage.comment}</p>
                </>
              ) : (
                <i>No comment on image</i>
              )}
            </>

            {selectedImage?.imageSource ? (
              <>
                <hr />
                <b>Image Source</b>
                <p style={{ textTransform: "capitalize" }}>
                  {selectedImage.imageSource}
                  {selectedImage.imageSource === "grading" &&
                  selectedImage.gradingId ? (
                    <>
                      {" - "}
                      <i>Added in Grading session {selectedImage.gradingId}</i>
                    </>
                  ) : null}
                </p>
              </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
        {debugMode ? (
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <b style={{ color: "lightcoral" }}>Debug Information</b>
            </Accordion.Header>
            <Accordion.Body>
              {Object.entries(selectedImage).map(([key, value], i) => (
                <div key={i}>
                  <b>{key}</b>{" "}
                  <code style={{ wordWrap: "break-word" }}>
                    {JSON.stringify(value)}
                  </code>
                  <br />
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ) : null}
      </Accordion>
    </>
  );
};

export default InventoryImagesComponent;
