import React, { useState, useEffect, useCallback } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InventoryImagesComponent from "../components/inventory/inventory-images";
import ImageUploadComponent, {
  FileWithComment,
} from "../components/inventory/image-upload-component";
import {
  GenerateGenericUploadImageUrlRequest,
  GetEntityListFromGenericImageUploads,
  GetGenericImagesFromEntityWithId,
  GetPresignedUrlForGenericEntityImagesUpload,
  UploadFileViaPresignedUrl,
} from "../workers/images";
import { InventoryItemStoredImage } from "../external/inventory-component/types";
import { Image } from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";
import { detectIsMobile } from "../helpers/mobile";
import { useLocalStorage } from "usehooks-ts";

const prefixOptions = [
  { value: "pm", display: "PM Item" },
  { value: "pallet", display: "Pallet" },
  { value: "lot", display: "Lot" },
  { value: "location", display: "Location" },
];

const ITEMS_PER_PAGE = 25;

/**
 * Query String Toggles:
 * - embedView: boolean (true/false) - Enables or disables the embed view.
 * - advancedFeatures: boolean (true/false) - Enables or disables advanced features.
 * - advancedFeaturesToggle: string (show/hide) - Shows or hides the toggle button for advanced features.
 * - page: string - The current page being viewed.
 * - entityId: string - The ID of the entity being viewed.
 */

const ImageUploadTool: React.FC = () => {
  const copyToClipboardButtonCopyText = "Copy to clipboard";
  const [searchParams, setSearchParams] = useSearchParams();
  const [entityId, setEntityId] = useState("");
  const [items, setItems] = useState<{ [key: string]: string[] }>({});
  const [images, setImages] = useState<InventoryItemStoredImage[]>([]);
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [files, setFiles] = useState<FileWithComment[]>([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [currentPage, setCurrentPage] = useState(prefixOptions[0].value);
  const [paginatedValuesPageNumber, setPaginatedValuesPageNumber] = useState(0);
  const [activeEntityId, setActiveEntityId] = useState("");
  const [showItemsModal, setShowItemsModal] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState(
    copyToClipboardButtonCopyText
  );
  const [isFetching, setIsFetching] = useState(false);

  const embedViewEnabled = searchParams.has("embedView");
  const advancedFeaturesState = searchParams.get("advancedFeatures");
  const advancedFeaturesToggleState = searchParams.get(
    "advancedFeaturesToggle"
  );

  const [showAdvanced, setShowAdvanced] = useLocalStorage(
    `cc-show-advanced-image-upload`,
    !embedViewEnabled && !detectIsMobile()
  );

  useEffect(() => {
    if (advancedFeaturesState === "true") {
      setShowAdvanced(true);
    } else if (advancedFeaturesState === "false") {
      setShowAdvanced(false);
    }
  }, [advancedFeaturesState, setShowAdvanced]);

  const handleFetchItems = async (prefix: string) => {
    setLoading((prev) => ({ ...prev, [prefix]: true }));
    try {
      const result = await GetEntityListFromGenericImageUploads(prefix);
      setItems((prev) => ({ ...prev, [prefix]: result }));
      setShowItemsModal(true); // Ensure modal is shown after fetching items
    } catch (error) {
      console.error("Error fetching items", error);
    } finally {
      setLoading((prev) => ({ ...prev, [prefix]: false }));
    }
  };

  const handleFetchImages = useCallback(
    async (item: string, pageOverride?: string) => {
      setLoading((prev) => ({ ...prev, images: true }));
      const itemTrimmed = item.trim().toUpperCase();

      try {
        const result = await GetGenericImagesFromEntityWithId(
          pageOverride ?? currentPage,
          itemTrimmed
        );
        setImages(result);
        setActiveEntityId(itemTrimmed); // Set active entity ID after fetching images
        setSearchParams((current) => ({
          ...Object.fromEntries(current.entries()),
          page: pageOverride ?? currentPage,
          entityId: itemTrimmed,
        }));
      } catch (error) {
        console.error("Error fetching images", error);
      } finally {
        setLoading((prev) => ({ ...prev, images: false }));
      }
    },
    [currentPage, setSearchParams]
  );

  useEffect(() => {
    const entityIdFromUrl = searchParams.get("entityId");
    const pageFromUrl = searchParams.get("page");
    if (pageFromUrl) {
      setCurrentPage(pageFromUrl);
    }
    if (entityIdFromUrl) {
      setActiveEntityId(entityIdFromUrl);
      handleFetchImages(entityIdFromUrl, pageFromUrl ?? undefined);
    }
  }, [handleFetchImages, searchParams]);

  const handleUploadImages = async (acceptedFiles: FileWithComment[]) => {
    setLoading((prev) => ({ ...prev, images: true }));
    try {
      const imagesForUpload = acceptedFiles.map(
        (file): GenerateGenericUploadImageUrlRequest => ({
          name: file.file.name
            // Replace space with underscore
            .replace(/ /g, "_")
            // Remove everything but alphanumeric characters, underscores, and periods
            .replace(/[^a-zA-Z0-9_.]/g, ""),
          comment: file.comment,
          imageSource: "upload",
        })
      );

      const presignedUrlResponse =
        await GetPresignedUrlForGenericEntityImagesUpload(
          currentPage,
          activeEntityId,
          imagesForUpload
        );
      const uploadPromises = presignedUrlResponse.presignedUrls.map(
        async (response, index) => {
          await UploadFileViaPresignedUrl(
            response.url,
            acceptedFiles[index].file,
            acceptedFiles[index].file.type
          );
        }
      );

      await Promise.all(uploadPromises);
      await handleFetchImages(entityId);
    } catch (error) {
      console.error("Error uploading images", error);
    } finally {
      setLoading((prev) => ({ ...prev, images: false }));
    }
  };

  const handleRemoveFile = (file: FileWithComment) => {
    setFiles(files.filter((f) => f !== file));
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    return () =>
      files.forEach((file) => URL.revokeObjectURL((file as any).preview));
  }, [files]);

  const paginatedItems = items[currentPage]?.slice(
    paginatedValuesPageNumber * ITEMS_PER_PAGE,
    (paginatedValuesPageNumber + 1) * ITEMS_PER_PAGE
  );

  const imageUrls = images.map((image) => image.imageUrl).join("|");

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(imageUrls).then(
      () => {
        setCopyButtonText("Copied!");
        setTimeout(
          () => setCopyButtonText(copyToClipboardButtonCopyText),
          2000
        );
      },
      (err) => {
        console.error("Failed to copy to clipboard", err);
      }
    );
  };

  const ExistingItemsModal = () => (
    <Modal
      show={showItemsModal}
      onHide={() => setShowItemsModal(false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Existing items with images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading[currentPage] ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Item ID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedItems?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {paginatedValuesPageNumber * ITEMS_PER_PAGE + index + 1}
                    </td>
                    <td>
                      <pre>{item}</pre>
                    </td>
                    <td>
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          handleFetchImages(item);
                          setShowItemsModal(false);
                        }}
                      >
                        Show Images <Image />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-between">
              <Button
                variant="outline-secondary"
                onClick={() =>
                  setPaginatedValuesPageNumber((prev) => Math.max(prev - 1, 0))
                }
                disabled={paginatedValuesPageNumber === 0}
              >
                Previous
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() =>
                  setPaginatedValuesPageNumber((prev) =>
                    items[currentPage]?.length > (prev + 1) * ITEMS_PER_PAGE
                      ? prev + 1
                      : prev
                  )
                }
                disabled={
                  items[currentPage]?.length <=
                  (paginatedValuesPageNumber + 1) * ITEMS_PER_PAGE
                }
              >
                Next
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
  const PrefixTabContent = (option: { value: string; display: string }) => (
    <Container className="mt-3">
      {!embedViewEnabled || !activeEntityId ? (
        <>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleFetchImages(entityId);
            }}
          >
            <Form.Group controlId="entityIdInput">
              <InputGroup className="w-100" style={{ maxWidth: "500px" }}>
                <Form.Control
                  type="text"
                  placeholder={`Enter ${option.display} ID`}
                  value={entityId}
                  onChange={(e) =>
                    setEntityId(e.target.value.trim().toUpperCase())
                  }
                />
                <Button
                  variant="success"
                  type="submit"
                  disabled={entityId.trim().length === 0 || loading.images}
                >
                  {loading.images ? (
                    <span>
                      <Spinner
                        as={"span"}
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        size="sm"
                        style={{ marginRight: "0.5em" }}
                      />
                      Loading...
                    </span>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
          <Button
            variant="outline-primary"
            className="mt-2 w-100"
            style={{ maxWidth: "500px" }}
            onClick={() => handleFetchItems(option.value)} // Fetch items when button is clicked
          >
            Show Existing Items
          </Button>
          <hr />
        </>
      ) : null}
      <ExistingItemsModal />
      {activeEntityId && (
        <>
          {images && images.length > 0 ? (
            <h5>
              Showing images for <b>{activeEntityId}</b>
            </h5>
          ) : (
            <h5>
              No images stored for item <b>{activeEntityId}</b>
            </h5>
          )}
          <Button
            variant="success"
            size="lg"
            className="w-100"
            style={{ maxWidth: "500px" }}
            onClick={() => setShowUploadModal(true)}
          >
            Add Images
          </Button>
          <hr />
          {showAdvanced &&
            (images.length > 0 ? (
              <>
                <p>
                  Copy pipe (<code>|</code>) separated URLs ({images.length}{" "}
                  images)
                </p>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    value={imageUrls}
                    readOnly
                    style={{
                      fontFamily: "monospace",
                      fontSize: "0.7em",
                    }}
                  />
                  <Button
                    variant="info"
                    size="sm"
                    onClick={handleCopyToClipboard}
                  >
                    {copyButtonText}
                  </Button>
                </InputGroup>
                <hr />
              </>
            ) : (
              <i>No images available to copy</i>
            ))}

          <Modal
            show={showUploadModal}
            onHide={() => setShowUploadModal(false)}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Upload Images</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ImageUploadComponent
                onDrop={(acceptedFiles) => {
                  setFiles(acceptedFiles);
                }}
                onRemoveFile={handleRemoveFile}
                isUploading={isUploadingImages}
                maxFiles={25}
                requiresUserEntry={setIsFetching} // Pass the state handler
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowUploadModal(false)}
                disabled={isUploadingImages || isFetching} // Disable button when fetching
              >
                Close
              </Button>
              <Button
                variant="success"
                onClick={async () => {
                  setIsUploadingImages(true);
                  await handleUploadImages(files);
                  setIsUploadingImages(false);
                  setShowUploadModal(false);
                }}
                disabled={isUploadingImages || isFetching} // Disable button when fetching
              >
                Save Images
                {isUploadingImages ? (
                  <Spinner style={{ marginLeft: "0.5em" }} size="sm" />
                ) : null}
              </Button>
            </Modal.Footer>
          </Modal>
          {images && images.length > 0 ? (
            <>
              <InventoryImagesComponent
                images={images}
                // deleteImageFunction={handleDeleteImage}
                maxWidth="700px"
              />
            </>
          ) : null}
        </>
      )}
    </Container>
  );

  return (
    <Container>
      <Row className="align-items-center mb-3">
        {!embedViewEnabled ? (
          <Col>
            <h4>Image Upload Tool</h4>
          </Col>
        ) : null}
        <Col className="d-flex justify-content-end">
          {advancedFeaturesToggleState !== "hide" && (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => setShowAdvanced(!showAdvanced)}
            >
              {showAdvanced
                ? "Hide Advanced Features"
                : "Show Advanced Features"}
            </Button>
          )}
        </Col>
      </Row>
      {!embedViewEnabled ? (
        <Tabs
          defaultActiveKey={prefixOptions[0].value}
          id="image-upload-tool-tabs"
          activeKey={currentPage}
          onSelect={(k) => {
            setCurrentPage(k || prefixOptions[0].value);
            setPaginatedValuesPageNumber(0);
            setSearchParams((current) => ({
              ...Object.fromEntries(current.entries()),
              page: k || prefixOptions[0].value,
              entityId: "",
            }));
            setEntityId("");
            setActiveEntityId("");
          }}
        >
          {prefixOptions.map((option) => (
            <Tab
              eventKey={option.value}
              title={option.display}
              key={option.value}
            >
              {PrefixTabContent(option)}
            </Tab>
          ))}
        </Tabs>
      ) : (
        PrefixTabContent(
          prefixOptions.find((option) => option.value === currentPage)!
        )
      )}
    </Container>
  );
};

export default ImageUploadTool;
