import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { QuestionCircle, Tablet } from "react-bootstrap-icons";
import { useMediaQuery, useSessionStorage } from "usehooks-ts";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useFeatureFlagEnabled, usePostHog } from "posthog-js/react";

import {
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  useAuth0,
  User,
} from "@auth0/auth0-react";

import "./App.scss";
import Dashboard from "./pages/dashboard";
import Home from "./pages/home";
import About from "./pages/about";
import navImageForLightBg from "./assets/clearcycle_logo_blacktext.png";
import navImageForDarkBg from "./assets/clearcycle_logo_whitetext.png";
import Dev from "./pages/dev";
import NavProfile, { UserAuthenticatedContext } from "./components/profile";
import Clients from "./pages/client/clients";
import ClientPage from "./pages/client/client";
import NewClientPage from "./pages/client/new-client";
import CatalogPage from "./pages/catalog";
import CatalogItemPage from "./pages/catalog-item";
import CatalogUpload from "./pages/catalog-upload";
import NewCatalogPage from "./pages/catalog/new-catalog";
import ImageHelpersPage from "./pages/image-helpers";
import CatalogUploadStatusPage from "./pages/catalog-upload-status";
import FinancePage from "./pages/finance";
import FinanceUpload from "./pages/finance-upload";
import GradingAndRefurbPage from "./pages/grading-and-refurb/grading-and-refurb";
import GradingAndRefurbNewPage from "./pages/grading-and-refurb/grading-and-refurb-new";
import Colourblindtest from "./pages/colourblindtest";
import MockupsPage from "./pages/mockups";
import ScannerTestPage from "./pages/scanner-test";
import { useDebugMode } from "./hooks/useDebugMode";
import DefaultRefurbMockup from "./pages/mockups/default";
import InboundPage from "./pages/inbound";
import InventoryScanPage from "./pages/inventory-scan";
import InventoryPage from "./pages/inventory/inventory";
import ManifestPage from "./pages/manifest/manifest";
import ManifestsPage from "./pages/manifest/manifests";
import NewManifestPage from "./pages/manifest/new-manifest";
import CatalogItemPublicPage from "./pages/catalog-item-public";
import ReportingDataUploadPage from "./pages/reporting-data-upload";
import jwt_decode from "jwt-decode";
import Reporting from "./pages/reporting";
import Novu from "./pages/novu";
import TasksPage from "./pages/task/tasks";
import Task from "./pages/task/task";
import GlobalOffCanvasComponent from "./components/globaloffcanvas";
import FaqPage from "./pages/faqs";
import CubeEstimator from "./pages/cube-estimator";
import Operations from "./pages/external-iframes/operations";
import ScannerHomePage from "./pages/scanner-home";
import { useCircleDarkMode } from "./hooks/useCircleDarkMode";
import AppsPage from "./pages/apps";
import InboundLoadPage from "./pages/inbound-load";
import TraderPage from "./pages/trader";
import { useCircleAuthScopesReadWrite } from "./hooks/useCircleAuthScopes";
import LabelGeneratorPage from "./pages/label-generator";
import PalletListPage from "./pages/pallet/pallet-list";
import PalletPage from "./pages/pallet/pallet-page";
import PalletLabelGeneratorPage from "./pages/pallet-label-generator";
import ItemLocationScanPage from "./pages/item-location";
import ItemLocationSearchLocationPage from "./pages/item-location-search-location";
import ImageUploadTool from "./pages/image-upload-tool";

function App({
  auth0Options,
}: {
  auth0Options: GetTokenSilentlyOptions | GetTokenWithPopupOptions;
}) {
  const location = useLocation();

  const [token, setToken] = useState<string | undefined>(undefined);
  const [userState, setUserState] = useState<User | undefined>(undefined);
  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
  const [showNavbar, setShowNavbar] = useState<boolean>(false);
  const [isRetrievingToken, setIsRetrievingToken] = useState(false);

  const [retrieveTokenError, setRetrieveTokenError] = useState<string>();
  const debugMode = useDebugMode();
  const darkMode = useCircleDarkMode();
  const [scopes, setScopes] = useCircleAuthScopesReadWrite([]);
  const tinyScreen = useMediaQuery("(max-width: 360px)");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("data-bs-theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const navigate = useNavigate();

  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    user,
    isAuthenticated,
  } = useAuth0();

  const posthog = usePostHog();
  const [sessionHasIdentifiedUser, setSessionHasIdentifiedUser] =
    useSessionStorage(`ccPosthogHasSentUser`, false);
  React.useEffect(() => {
    posthog.capture("$pageview");
  }, [location, posthog]);

  const allowInventory = useFeatureFlagEnabled("inventory");
  const allowManifests = useFeatureFlagEnabled("manifests");
  const showMockups = useFeatureFlagEnabled("showMockups");

  useEffect(() => {
    if (isAuthenticated) {
      setUserAuthenticated(true);
      setUserState(user);
      if (!sessionHasIdentifiedUser) {
        posthog?.identify(user?.email, {
          email: user?.email,
        });
        setSessionHasIdentifiedUser(true);
      }
    }
  }, [
    isAuthenticated,
    posthog,
    sessionHasIdentifiedUser,
    setSessionHasIdentifiedUser,
    user,
  ]);

  const getAccessToken = useCallback(() => {
    // This is here to reference the token in the callback, so that the
    // callback is updated when the `isRetrievingToken` variable changes
    console.info(`isRetrievingToken: ${isRetrievingToken}`);
    setIsRetrievingToken(true);
    getAccessTokenSilently({
      // If we don't provide this, we get a popup
      authorizationParams: auth0Options.authorizationParams,
      cacheMode: auth0Options.cacheMode,
    })
      .then((token) => {
        setToken(token);
        console.info("Retrieved token silently");
        setIsRetrievingToken(false);
      })
      .catch((err) => {
        console.error(err);
        setRetrieveTokenError(undefined);
        // A popup is required when new scopes are requested
        getAccessTokenWithPopup({
          authorizationParams: auth0Options.authorizationParams,
          cacheMode: auth0Options.cacheMode,
        })
          .then((token) => {
            setToken(token);
            console.info("Retrieved token with popup");
          })
          .catch((err) => {
            console.error(err);
            setRetrieveTokenError(
              "An error occurred while retrieving login information. You may have a popup blocked. Please check for blocked popups in your address bar. Or, you can logout and login again and that should fix your issue."
            );
          })
          .finally(() => {
            setIsRetrievingToken(false);
          });
      });
  }, [
    isRetrievingToken,
    setIsRetrievingToken,
    getAccessTokenSilently,
    auth0Options.authorizationParams,
    auth0Options.cacheMode,
    getAccessTokenWithPopup,
  ]);

  useEffect(() => {
    if (userAuthenticated) {
      getAccessToken();
    }
  }, [userAuthenticated, getAccessToken]);

  useEffect(() => {
    if (token !== undefined) {
      const decodedToken: any = jwt_decode(token);
      const scopeArray: string[] = decodedToken.scope.split(" ");
      setScopes(scopeArray);
    }
  }, [setScopes, token]);

  useEffect(() => {
    const hideNavbarLocations = [
      "/scannerhome",
      "/tradeorders",
      "/pallet-label-generator",
    ];
    const hideNavbarQueryParam = searchParams.get("hideNavbar") === "true";
    if (
      hideNavbarLocations.includes(location.pathname) ||
      hideNavbarQueryParam
    ) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [location.pathname, searchParams]);

  const appsPageEnabled = useFeatureFlagEnabled("appsPageEntries");

  const CircleNavbar = () => (
    <Navbar
      expand="xl"
      style={{ marginBottom: tinyScreen ? "0.25em" : "1.5em" }}
      className={!showNavbar ? "d-none" : ""}
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            src={darkMode ? navImageForDarkBg : navImageForLightBg}
            className={"nav-image"}
            alt="Logo"
            height={30}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {isRetrievingToken ? (
          <Spinner animation="border" size="sm"></Spinner>
        ) : (
          <Navbar.Collapse>
            <Nav
              style={{ marginRight: "auto" }}
              defaultActiveKey={"/"}
              className={"menu"}
            >
              {scopes.includes("read:client-item") ? (
                <Nav.Link
                  active={location.pathname === "/clients"}
                  href="/clients"
                >
                  Clients
                </Nav.Link>
              ) : null}
              {isAuthenticated && allowManifests ? (
                <Nav.Link
                  active={location.pathname === "/manifests"}
                  href="/manifests"
                >
                  Manifests
                </Nav.Link>
              ) : null}
              {scopes.includes("read:catalogue-item") ? (
                <Nav.Link
                  active={location.pathname === "/catalog"}
                  href="/catalog"
                >
                  Catalogue
                </Nav.Link>
              ) : null}
              {allowInventory /*&& scopes.includes("read:inventory-item")*/ ? (
                <Nav.Link
                  active={location.pathname === "/pallets"}
                  href="/pallets"
                >
                  Pallets
                </Nav.Link>
              ) : null}
              {allowInventory && scopes.includes("read:inventory-item") ? (
                <Nav.Link
                  active={location.pathname === "/inventory"}
                  href="/inventory"
                >
                  Inventory
                </Nav.Link>
              ) : null}
              {scopes.includes("read:inventory-item") ? (
                <Nav.Link
                  active={location.pathname === "/inbound"}
                  href="/inbound"
                >
                  Inbound
                </Nav.Link>
              ) : null}
              {scopes.some((scope) => scope.startsWith("read:task-item:")) ? (
                <Nav.Link active={location.pathname === "/tasks"} href="/tasks">
                  Tasks
                </Nav.Link>
              ) : null}
              {scopes.some((scope) => scope.includes("read:finance-data")) ? (
                <Nav.Link
                  active={location.pathname === "/finance"}
                  href="/finance"
                >
                  Finance
                </Nav.Link>
              ) : null}
              {isAuthenticated && appsPageEnabled ? (
                <Nav.Link active={location.pathname === "/apps"} href="/apps">
                  Apps
                </Nav.Link>
              ) : null}
              {isAuthenticated ? (
                <Nav.Link
                  active={location.pathname === "/reporting"}
                  href="/reporting"
                >
                  Reporting
                </Nav.Link>
              ) : null}
              {isAuthenticated ? (
                <NavDropdown title="Utilities">
                  {scopes.includes("use:image-downloader") ? (
                    <NavDropdown.Item onClick={() => navigate("/imagehelpers")}>
                      Image Utilities
                    </NavDropdown.Item>
                  ) : null}
                  {scopes.includes("use:reporting-data-uploader") ? (
                    <NavDropdown.Item
                      onClick={() => navigate("/reporting-data-upload")}
                    >
                      Reporting Data Upload
                    </NavDropdown.Item>
                  ) : null}
                  <NavDropdown.Item
                    active={location.pathname === "/cbmCalculator"}
                    href="/cbmCalculator"
                  >
                    Cube Calculator
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    active={location.pathname === "/item-location"}
                    href="/item-location"
                  >
                    Item Location
                  </NavDropdown.Item>
                  {showMockups ? (
                    <NavDropdown.Item onClick={() => navigate("/mockups")}>
                      Mockups
                    </NavDropdown.Item>
                  ) : null}
                  <NavDropdown.Item onClick={() => navigate("/scannerhome")}>
                    Scanner Home{" "}
                    <Tablet size={14} color={darkMode ? "coral" : "green"} />
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    active={location.pathname === "/image-upload-tool"}
                    href="/image-upload-tool"
                  >
                    Image Upload Tool
                  </NavDropdown.Item>
                  {process.env.REACT_APP_CCENV === "dev" && (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => navigate("/dev/scanner-test")}
                      >
                        Scanner Test
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => navigate("/colourblindtest")}
                      >
                        Colour Blind Test
                      </NavDropdown.Item>
                    </>
                  )}
                  {process.env.REACT_APP_CCENV === "dev" || debugMode ? (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={() => navigate("/dev")}>
                        Dev Tools
                      </NavDropdown.Item>
                    </>
                  ) : null}
                </NavDropdown>
              ) : null}
            </Nav>
            <Nav style={{ justifyContent: "flex-end", alignItems: "center" }}>
              {process.env.REACT_APP_CCENV === "dev" ? (
                <span style={{ color: "darkcyan" }}>DEV</span>
              ) : null}
              {debugMode ? (
                <span style={{ marginLeft: "0.25em", color: "darkred" }}>
                  DEBUG
                </span>
              ) : null}
              <NavProfile />
              {userAuthenticated ? <Novu /> : null}
            </Nav>
            <QuestionCircle
              onClick={() => navigate("/faqs")}
              size={25}
              color="blue"
              style={{ marginLeft: "15px" }}
            />
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
  return (
    <UserAuthenticatedContext.Provider
      value={{
        token,
        user: userState,
        scopes: scopes,
      }}
    >
      <div>
        <GlobalOffCanvasComponent />
        <CircleNavbar />
        {retrieveTokenError ? (
          <Container>
            <Alert variant="danger">{retrieveTokenError}</Alert>
          </Container>
        ) : null}
        {isRetrievingToken ? (
          <div className="fade modal-backdrop show">
            <Spinner
              animation="border"
              variant="light"
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          </div>
        ) : null}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/scannerhome" element={<ScannerHomePage />} />
          <Route path="/catalog" element={<CatalogPage />} />
          <Route path="/catalog/upload" element={<CatalogUpload />} />
          <Route
            path="/catalog/uploaded"
            element={<CatalogUploadStatusPage />}
          />
          <Route path="/catalog/item/:itemId" element={<CatalogItemPage />} />
          <Route path="/catalog/new" element={<NewCatalogPage />} />
          <Route path="/tasks" element={<TasksPage />} />
          <Route
            path="/reporting-data-upload"
            element={<ReportingDataUploadPage />}
          />
          <Route path="/finance" element={<FinancePage />} />
          <Route path="/finance/upload" element={<FinanceUpload />} />
          <Route path="/reporting" element={<Reporting />} />
          <Route
            path="/catalog/item/public/:itemId"
            element={<CatalogItemPublicPage />}
          />
          <Route path="/clients" element={<Clients />} />
          <Route path="/client/new" element={<NewClientPage />} />
          <Route path="/client/:clientId" element={<ClientPage />} />
          {allowInventory ? (
            <Route path="/inventory" element={<InventoryPage />} />
          ) : null}
          <Route path="/inventory/item" element={<InventoryScanPage />} />
          <Route path="inbound" element={<InboundPage />} />
          <Route path="inbound/inbound-load" element={<InboundLoadPage />} />
          <Route path="item-location" element={<ItemLocationScanPage />} />
          <Route
            path="/gradingandrefurb/:itemId/new"
            element={<GradingAndRefurbNewPage />}
          />
          <Route
            path="/gradingandrefurb/:itemId/:gradingAndRefurbId"
            element={<GradingAndRefurbPage />}
          />
          <Route path="/imagehelpers" element={<ImageHelpersPage />} />
          {/** Redirection only on this page for bookmark preservation */}
          <Route path="/operations" element={<Operations />} />
          <Route path="/apps" element={<AppsPage />} />
          <Route path="/dev" element={<Dev />} />
          <Route path="/dev/scanner-test" element={<ScannerTestPage />} />
          {allowManifests ? (
            <Route path="/manifest/:manifestId" element={<ManifestPage />} />
          ) : null}
          {allowManifests ? (
            <Route path="/manifest/new" element={<NewManifestPage />} />
          ) : null}
          {allowManifests ? (
            <Route path="/manifests" element={<ManifestsPage />} />
          ) : null}
          {showMockups ? (
            <Route path="/mockups" element={<MockupsPage />} />
          ) : null}
          {showMockups ? (
            <Route path="/mockups/default" element={<DefaultRefurbMockup />} />
          ) : null}
          <Route path="/refurb/:itemId" element={<GradingAndRefurbPage />} />
          <Route path="/colourblindtest" element={<Colourblindtest />} />
          <Route path="/task/:taskId" element={<Task taskId="taskId" />} />
          <Route path="/faqs" element={<FaqPage />} />
          <Route path="/cbmCalculator" element={<CubeEstimator />} />
          <Route path="/tradeorders" element={<TraderPage />} />
          <Route
            path="/label-generator"
            element={<LabelGeneratorPage autoPrint={true} />}
          />
          <Route path="/pallets" element={<PalletListPage />} />
          <Route path="/pallet/:palletNumber" element={<PalletPage />} />
          <Route
            path="/pallet-label-generator"
            element={<PalletLabelGeneratorPage autoPrint={true} />}
          />
          <Route
            path="/item-location-search"
            element={<ItemLocationSearchLocationPage />}
          />
          <Route path="/image-upload-tool" element={<ImageUploadTool />} />
        </Routes>
      </div>
    </UserAuthenticatedContext.Provider>
  );
}

export default App;
