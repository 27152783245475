import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { ExclamationCircle } from "react-bootstrap-icons";
import { ErrorBoundary } from "react-error-boundary";
import { InventoryItem } from "../../external/inventory-component/types";
import { InventoryFilters } from "../../workers/inventory";
import { useContext, useState } from "react";
import { UserAuthenticatedContext } from "../profile";
import Loading from "../loading";
import InventoryRowComponent from "./inventory-row";

export interface InventoryTableProps {
  inventoryItems: InventoryItem[];
  inventoryItemsLoaded: boolean;
  filters: InventoryFilters;
  childToParentTableSort: (
    sortField: string,
    sortDirection: "desc" | "asc"
  ) => void;
  isError: boolean;
  error: string | null;
}

const InventoryTableComponent = ({
  inventoryItems,
  inventoryItemsLoaded,
  filters,
  childToParentTableSort,
  isError,
  error,
}: InventoryTableProps): JSX.Element => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [sortField, setSortField] = useState<string>(filters.sortField);
  const [sortDirection, setSortDirection] = useState<string>(
    filters.sortDirection
  );

  const handleSort = (field: string) => {
    const newSortDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newSortDirection);
    childToParentTableSort(field, newSortDirection);
  };

  const headers = [
    { name: "Id", sortKey: "id.keyword" },
    { name: "Client ID", sortKey: "clientId.keyword" },
    { name: "Sub Client ID", sortKey: "subClientId.keyword" },
    { name: "Load Reference", sortKey: "loadReferenceId.keyword" },
    { name: "Client SKU", sortKey: "clientItemId.keyword" },
    { name: "Name", sortKey: "description.keyword" },
    { name: "Arrival Date", sortKey: "arrivalDate" },
  ];

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => {
        return (
          <td>
            <ExclamationCircle
              style={{ marginBottom: "0.25em", marginRight: "0.25em" }}
              size={18}
              color="red"
            />
            Error occurred rendering table. Please share the below error with
            support.
            <hr />
            <pre>Error rendering CatalogTableComponent</pre>
            <pre>{error.message}</pre>
          </td>
        );
      }}
    >
      <Container style={{ width: "100%", minWidth: "100%", overflowX: "auto" }}>
        <Table>
          <thead>
            <tr style={{ fontWeight: "bolder" }}>
              {headers.map(({ name, sortKey }, i) => (
                <th
                  className="sticky"
                  key={i}
                  onClick={() => sortKey && handleSort(sortKey)}
                  style={{
                    cursor: sortKey ? "pointer" : "default",
                    opacity: sortKey === sortField ? 1 : 0.4,
                  }}
                >
                  {name}{" "}
                  {sortKey && sortField === sortKey
                    ? sortDirection === "asc"
                      ? "↑"
                      : "↓"
                    : ""}
                </th>
              ))}
              {userAuthenticatedContext.scopes?.includes(
                "create:inventory-item"
              ) && <th></th>}
            </tr>
          </thead>
          <tbody>
            {isError ? (
              <>
                {console.error("Error fetching inventory items", error)}
                <tr>
                  <td colSpan={headers.length}>{error}</td>
                </tr>
              </>
            ) : inventoryItemsLoaded ? (
              !inventoryItems || inventoryItems.length === 0 ? (
                <tr>
                  <td colSpan={headers.length}>No items found</td>
                </tr>
              ) : (
                inventoryItems.map((item, index) => (
                  <InventoryRowComponent
                    inventoryItem={item}
                    key={`inventory-item-${index}`}
                  />
                ))
              )
            ) : null}
          </tbody>
        </Table>
        {!inventoryItemsLoaded && !isError ? <Loading /> : null}
      </Container>
    </ErrorBoundary>
  );
};

export default InventoryTableComponent;
