/// <reference types="react-scripts" />

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import {
  ClientRateCardAuction,
  ClientRateCardRetail,
  RateFlatFee,
  RatePercentage,
  SubClient,
} from "../../external";
import SubClientRateTextBoxComponent from "./subclient-rate-textbox";
import { useCallback, useContext, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
import { useForm } from "react-hook-form";
import { UserAuthenticatedContext } from "../profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { DashCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import Loading from "../loading";
import {
  ClientRateCardAuctionField,
  ClientRateCardRetailField,
  RateType,
} from "./types";

const createClientWorker = createWorkerFactory(
  () => import("../../workers/clients")
);

export type SubClientProps = {
  action: "create" | "update";
  clientId: string;
  subClient: SubClient;
  childToParentIsSaving?: (value: boolean) => void;
  childToParentRefresh?: (value: boolean) => void;
  childToParentCloseModal?: () => void;
};

const SubClientComponent = ({
  action,
  clientId,
  subClient,
  childToParentIsSaving,
  childToParentRefresh,
  childToParentCloseModal,
}: SubClientProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubClient>();

  const clientWorker = useWorker(createClientWorker);

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [userToken, setUserToken] = useState<string>();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showPostError, setShowPostError] = useState(false);
  const [postError, setPostError] = useState<string>();

  const [retail, setRetail] = useState<ClientRateCardRetail | undefined>(
    subClient.rateCard?.retail
  );
  const [auction, setAuction] = useState<ClientRateCardAuction | undefined>(
    subClient.rateCard?.auction
  );

  const [hideRetailButton, setHideRetailButton] = useState<boolean>(
    subClient.rateCard?.retail !== undefined
  );
  const [hideAuctionButton, setHideAuctionButton] = useState<boolean>(
    subClient.rateCard?.auction !== undefined
  );

  const [showRetailForm, setShowRetailForm] = useState<boolean>(
    subClient.rateCard?.retail !== undefined
  );
  const [showAuctionForm, setShowAuctionForm] = useState<boolean>(
    subClient.rateCard?.auction !== undefined
  );

  const [blockFormSubmitRetail, setBlockFormSubmitRetail] =
    useState<boolean>(false);
  const [blockFormSubmitAuction, setBlockFormSubmitAuction] =
    useState<boolean>(false);

  const [data] = useState<SubClient>(subClient);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  const handleRetail = useCallback(
    (valid: boolean, value?: ClientRateCardRetail) => {
      if (valid) {
        setRetail(value);
        setBlockFormSubmitRetail(false);
      } else {
        setRetail(undefined);
        setBlockFormSubmitRetail(true);
      }
    },
    [] // Empty dependency array, assuming handleRetail doesn't depend on any changing state/props
  );

  const handleAuction = useCallback(
    (valid: boolean, value?: ClientRateCardAuction) => {
      if (valid) {
        setAuction(value);
        setBlockFormSubmitAuction(false);
      } else {
        setAuction(undefined);
        setBlockFormSubmitAuction(true);
      }
    },
    [] // Empty dependency array, assuming handleAuction doesn't depend on any changing state/props
  );

  const onSubmit = handleSubmit(
    (formSubClient) => {
      if (formSubClient) {
        (async () => {
          if (userToken) {
            setIsSaving(true);
            if (childToParentIsSaving) {
              childToParentIsSaving(true);
            }
            setPostError("");
            setShowPostError(false);
            formSubClient.defaultCostPriceMultiplier = parseFloat(
              String(formSubClient.defaultCostPriceMultiplier)
            );

            try {
              const result = await clientWorker.upsertSubClient(
                action,
                clientId,
                formSubClient,
                userToken,
                retail,
                auction
              );

              if ([200].includes(result.status)) {
                if (childToParentRefresh) {
                  childToParentRefresh(true);
                }

                if (childToParentCloseModal) {
                  childToParentCloseModal();
                }
              } else {
                setShowPostError(true);
                setPostError(
                  `Invalid status code ${result.status} returned from server`
                );
              }
            } catch (e) {
              await new Promise((resolve) =>
                setTimeout(() => {
                  setShowPostError(true);
                  setPostError(
                    `Sub Client failed with error "${
                      (e as unknown as Error).message
                    }"`
                  );
                  resolve(undefined);
                }, 2000)
              );
            } finally {
              await new Promise((resolve) => {
                // Give a small timeout here to give the illusion of loading
                setTimeout(() => {
                  setIsSaving(false);
                  if (childToParentIsSaving) {
                    childToParentIsSaving(false);
                  }
                  resolve(undefined);
                }, 2000);
              });
            }
          }
        })();
      }
    },
    (invalidData) => {
      console.log(errors);
      console.error("invalid submit attempted");
      console.table(invalidData);
    }
  );

  return (
    <Form onSubmit={onSubmit}>
      <Container>
        {showPostError && !isSaving ? (
          <Alert
            variant="danger"
            dismissible
            onClose={() => {
              setPostError(undefined);
              setShowPostError(false);
            }}
          >
            {postError}
          </Alert>
        ) : (
          <></>
        )}
      </Container>
      <Row>{isSaving ? <Loading /> : <></>}</Row>
      <Row className="mb-2">
        <Form.Group className="mb-3" as={Col} md="4">
          <Form.Label>Name</Form.Label>
          <Form.Control
            defaultValue={data.displayInfo.name}
            {...register("displayInfo.name", {
              required: true,
              minLength: 2,
              maxLength: 20,
            })}
            isInvalid={
              errors.displayInfo?.name?.type === "required" ||
              errors.displayInfo?.name?.type === "minLength" ||
              errors.displayInfo?.name?.type === "maxLength"
            }
            as="input"
            disabled={isSaving}
          />
          <Form.Control.Feedback type="invalid">
            Name must be between 2 and 20 characters long.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" as={Col} md="4">
          <Form.Label>SKU Suffix</Form.Label>
          <Form.Control
            defaultValue={data.skuSuffix}
            {...register("skuSuffix", {
              required: true,
            })}
            isInvalid={errors.skuSuffix?.type === "required"}
            as="input"
            disabled={isSaving}
          />
          <Form.Control.Feedback type="invalid">
            SKU Suffix is required.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" as={Col} md="4">
          <Form.Label>Sub Client ID</Form.Label>
          <Form.Control
            defaultValue={data.subClientId}
            {...register("subClientId", {
              required: true,
            })}
            isInvalid={errors.subClientId?.type === "required"}
            as="input"
            disabled={isSaving}
            readOnly={action === "update"}
          />
          <Form.Control.Feedback type="invalid">
            Sub Client ID is required.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" as={Col} md="4">
          <Form.Label>Brand</Form.Label>
          <Form.Control
            defaultValue={data.brand}
            {...register("brand", {})}
            as="input"
            disabled={isSaving}
          />
        </Form.Group>
        <Form.Group className="mb-3" as={Col} md="4">
          <Form.Label>Shipping Method Multiplier</Form.Label>
          <Form.Control
            defaultValue={data.shippingMethodMultiplier}
            {...register("shippingMethodMultiplier", {
              pattern: /^(0|\d+(\.\d{0,2})?)$/,
            })}
            isInvalid={errors.shippingMethodMultiplier?.type === "pattern"}
            type="number"
            disabled={isSaving}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid value. Whole numbers or numbers with 2 decimal
            places are valid.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" as={Col} md="4">
          <Form.Label>Packaging Rate</Form.Label>
          <InputGroup>
            <InputGroup.Text>£</InputGroup.Text>
            <Form.Control
              defaultValue={data.packagingRate?.value.amount ?? 0}
              {...register("packagingRate.value.amount", {})}
              type="number"
              disabled={isSaving}
            />
          </InputGroup>
        </Form.Group>
      </Row>
      <hr />
      <h2>Rate Card</h2>
      <Row>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h5 className="mb-3 pt-3">Retail</h5>
          {!hideRetailButton ? (
            <PlusCircleFill
              size={25}
              color="blue"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!isSaving) {
                  handleRetail(false, undefined);
                  setShowRetailForm(true);
                  setHideRetailButton(true);
                }
              }}
            />
          ) : (
            <DashCircleFill
              size={25}
              color="blue"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!isSaving) {
                  handleRetail(true, undefined);
                  setShowRetailForm(false);
                  setHideRetailButton(false);
                }
              }}
            />
          )}
        </div>
        {showRetailForm && (
          <RateCardRetailComponent
            retail={data.rateCard?.retail}
            isSaving={isSaving}
            isRetailValid={handleRetail}
          ></RateCardRetailComponent>
        )}
      </Row>
      <Row>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h5 className="mb-3 pt-3">Auction</h5>
          {!hideAuctionButton ? (
            <PlusCircleFill
              size={25}
              color="blue"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!isSaving) {
                  handleAuction(false, undefined);
                  setShowAuctionForm(true);
                  setHideAuctionButton(true);
                }
              }}
            />
          ) : (
            <DashCircleFill
              size={25}
              color="blue"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!isSaving) {
                  handleAuction(true, undefined);
                  setShowAuctionForm(false);
                  setHideAuctionButton(false);
                }
              }}
            />
          )}
        </div>
        {showAuctionForm && (
          <RateCardAuctionComponent
            auction={data.rateCard?.auction}
            isSaving={isSaving}
            isAuctionValid={handleAuction}
          ></RateCardAuctionComponent>
        )}
      </Row>
      <hr />
      <Row>
        <Col className="m-2">
          <Button
            size={"lg"}
            className="mb-3"
            variant="primary"
            type="submit"
            disabled={
              isSaving || blockFormSubmitRetail || blockFormSubmitAuction
            }
          >
            <Spinner
              as={"span"}
              animation="border"
              role="status"
              aria-hidden="true"
              size="sm"
              style={{ marginRight: "0.5em" }}
              className={isSaving ? "" : "visually-hidden"}
            />
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

interface RateCardRetailComponentProps {
  retail?: ClientRateCardRetail;
  isSaving: boolean;
  isRetailValid: (valid: boolean, value?: ClientRateCardRetail) => void;
}

export const RateCardRetailComponent = ({
  retail,
  isSaving,
  isRetailValid,
}: RateCardRetailComponentProps) => {
  const [exitRate, setExitRate] = useState<
    undefined | RatePercentage | RateFlatFee
  >();

  const [marketingRate, setMarketingRate] = useState<
    undefined | RatePercentage | RateFlatFee
  >();

  const [marketplaceAndPaymentFeesRate, setMarketplaceAndPaymentFeesRate] =
    useState<undefined | RatePercentage | RateFlatFee>();

  const [perItemBaseRate, setPerItemBaseRate] = useState<
    undefined | RatePercentage | RateFlatFee
  >();

  const handleRetailData = useCallback(
    (
      field: ClientRateCardRetailField,
      valid: boolean,
      type?: RateType,
      value?: number,
      data?: RatePercentage | RateFlatFee
    ) => {
      if (valid && data) {
        switch (field) {
          case ClientRateCardRetailField.marketingRate:
            setMarketingRate(data);
            break;
          case ClientRateCardRetailField.exitRate:
            setExitRate(data);
            break;
          case ClientRateCardRetailField.marketplaceAndPaymentFeesRate:
            setMarketplaceAndPaymentFeesRate(data);
            break;
          case ClientRateCardRetailField.perItemBaseRate:
            setPerItemBaseRate(data);
            break;
        }
      } else {
        switch (field) {
          case ClientRateCardRetailField.marketingRate:
            setMarketingRate(undefined);
            break;
          case ClientRateCardRetailField.exitRate:
            setExitRate(undefined);
            break;
          case ClientRateCardRetailField.marketplaceAndPaymentFeesRate:
            setMarketplaceAndPaymentFeesRate(undefined);
            break;
          case ClientRateCardRetailField.perItemBaseRate:
            setPerItemBaseRate(undefined);
            break;
        }
      }
    },
    []
  );

  const memoizedIsRetailValid = useCallback(isRetailValid, [isRetailValid]);

  useEffect(() => {
    if (
      !perItemBaseRate ||
      !marketingRate ||
      !marketplaceAndPaymentFeesRate ||
      !exitRate
    ) {
      memoizedIsRetailValid(false, undefined);
    } else {
      memoizedIsRetailValid(true, {
        exitRate: exitRate,
        marketingRate: marketingRate,
        marketplaceAndPaymentFeesRate: marketplaceAndPaymentFeesRate,
        shippingAndHandling: { perItemBaseRate: perItemBaseRate },
      });
    }
  }, [
    perItemBaseRate,
    marketingRate,
    marketplaceAndPaymentFeesRate,
    exitRate,
    memoizedIsRetailValid,
  ]);

  return (
    <Row className="mb-2">
      <Form.Group className="mb-3" as={Col} md="4">
        <Form.Label>Exit Rate</Form.Label>
        <SubClientRateTextBoxComponent
          defaultValue={retail?.exitRate}
          childToParent={handleRetailData}
          isSaving={isSaving}
          field={ClientRateCardRetailField.exitRate}
        ></SubClientRateTextBoxComponent>
      </Form.Group>
      <Form.Group className="mb-3" as={Col} md="4">
        <Form.Label>Marketing Rate</Form.Label>
        <SubClientRateTextBoxComponent
          defaultValue={retail?.marketingRate}
          childToParent={handleRetailData}
          isSaving={isSaving}
          field={ClientRateCardRetailField.marketingRate}
        ></SubClientRateTextBoxComponent>
      </Form.Group>
      <Form.Group className="mb-3" as={Col} md="4">
        <Form.Label>Marketplace & Payment Fees</Form.Label>
        <SubClientRateTextBoxComponent
          defaultValue={retail?.marketplaceAndPaymentFeesRate}
          childToParent={handleRetailData}
          isSaving={isSaving}
          field={ClientRateCardRetailField.marketplaceAndPaymentFeesRate}
        ></SubClientRateTextBoxComponent>
      </Form.Group>
      <Form.Group className="mb-3" as={Col} md="4">
        <Form.Label>Per Item Base Rate</Form.Label>
        <SubClientRateTextBoxComponent
          defaultValue={retail?.shippingAndHandling.perItemBaseRate}
          childToParent={handleRetailData}
          isSaving={isSaving}
          field={ClientRateCardRetailField.perItemBaseRate}
        ></SubClientRateTextBoxComponent>
      </Form.Group>
    </Row>
  );
};

interface RateCardAuctionComponentProps {
  auction?: ClientRateCardAuction;
  isSaving: boolean;
  isAuctionValid: (valid: boolean, value?: ClientRateCardAuction) => void;
}

export const RateCardAuctionComponent = ({
  auction,
  isSaving,
  isAuctionValid,
}: RateCardAuctionComponentProps) => {
  const [exitRateBer, setExitRateBer] = useState<
    undefined | RatePercentage | RateFlatFee
  >();

  const [exitRateNonBer, setExitRateNonBer] = useState<
    undefined | RatePercentage | RateFlatFee
  >();

  const [handlingCharge, setHandlingCharge] = useState<
    undefined | RatePercentage | RateFlatFee
  >();

  const handleAuctionData = useCallback(
    (
      field: ClientRateCardAuctionField,
      valid: boolean,
      type?: RateType,
      value?: number,
      data?: RatePercentage | RateFlatFee
    ) => {
      if (valid && data) {
        switch (field) {
          case ClientRateCardAuctionField.ber:
            setExitRateBer(data);
            break;
          case ClientRateCardAuctionField.handlingCharge:
            setHandlingCharge(data);
            break;
          case ClientRateCardAuctionField.nonBer:
            setExitRateNonBer(data);
            break;
        }
      } else {
        switch (field) {
          case ClientRateCardAuctionField.ber:
            setExitRateBer(undefined);
            break;
          case ClientRateCardAuctionField.handlingCharge:
            setHandlingCharge(undefined);
            break;
          case ClientRateCardAuctionField.nonBer:
            setExitRateNonBer(undefined);
            break;
        }
      }
    },
    []
  );

  const memoizedIsAuctionValid = useCallback(isAuctionValid, [isAuctionValid]);

  useEffect(() => {
    if (!exitRateBer || !exitRateNonBer || !handlingCharge) {
      memoizedIsAuctionValid(false, undefined);
    } else {
      memoizedIsAuctionValid(true, {
        auctionExitRate: {
          nonBer: exitRateNonBer,
          ber: exitRateBer,
        },
        handlingCharge: handlingCharge,
      });
    }
  }, [exitRateBer, exitRateNonBer, handlingCharge, memoizedIsAuctionValid]);

  return (
    <Row className="mb-2">
      <Form.Group className="mb-3" as={Col} md="4">
        <Form.Label>Exit Rate (Non-BER)</Form.Label>
        <SubClientRateTextBoxComponent
          childToParent={handleAuctionData}
          isSaving={isSaving}
          field={ClientRateCardAuctionField.nonBer}
          defaultValue={auction?.auctionExitRate.nonBer}
        ></SubClientRateTextBoxComponent>
      </Form.Group>
      <Form.Group className="mb-3" as={Col} md="4">
        <Form.Label>Exit Rate (BER)</Form.Label>
        <SubClientRateTextBoxComponent
          childToParent={handleAuctionData}
          isSaving={isSaving}
          field={ClientRateCardAuctionField.ber}
          defaultValue={auction?.auctionExitRate.ber}
        ></SubClientRateTextBoxComponent>
      </Form.Group>
      <Form.Group className="mb-3" as={Col} md="4">
        <Form.Label>Handling Charge</Form.Label>
        <SubClientRateTextBoxComponent
          childToParent={handleAuctionData}
          isSaving={isSaving}
          field={ClientRateCardAuctionField.handlingCharge}
          defaultValue={auction?.handlingCharge}
        ></SubClientRateTextBoxComponent>
      </Form.Group>
    </Row>
  );
};

export default SubClientComponent;
