export const detectIsMobile = () => {
  // Not supported in all browsers (Safari being the main one we care about here)
  // https://caniuse.com/mdn-api_navigator_useragentdata
  if ((navigator as any).userAgentData) {
    return (navigator as any).userAgentData.mobile;
  }

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    // true for mobile device
    return true;
  } else {
    // false for not mobile device
    return false;
  }
};
